import styled from "styled-components";
const HeaderEl = styled.header `
  font-family: monospace;
  padding: 1.5em;
`;
const HeaderLink = styled.a `
  color: rgb(102, 179, 217);
  text-decoration: none;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
`;
const headerLinks = [
    {
        name: "curtis davies",
        url: "#",
    },
    {
        name: "linkedin",
        url: "https://www.linkedin.com/in/curtis-rw-davies/",
    },
    {
        name: "gitlab",
        url: "https://gitlab.com/110Percent",
    },
    {
        name: "github",
        url: "https://github.com/110Percent",
    },
    {
        name: "email",
        url: "mailto:curtis.davies@riseup.net",
    },
    {
        name: "87c186b7",
        url: new URL("../files/key.txt", import.meta.url),
    },
];
export default function Header() {
    return (<HeaderEl>
      <LinkList links={headerLinks}/>
    </HeaderEl>);
}
function LinkList({ links }) {
    return (<div>
      {links
            .map((link) => (<HeaderLink href={link.url.toString()}>{link.name}</HeaderLink>))
            .reduce((prev, curr) => [prev, " - ", curr])}
    </div>);
}
