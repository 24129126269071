import styled from "styled-components";
function emoji() {
    const emojis = ["❤️", "✨", "🫠", "🎉", "👋", "✌️", "😊", "🙃"];
    const emotes = [
        "638293995663130624.gif",
        "863793896933031967.gif",
        "774268155686158336.gif",
        "776085967818850336.gif",
    ];
    const id = Math.floor(Math.random() * (emojis.length + emotes.length));
    if (id < emojis.length) {
        return <span>{emojis[id]}</span>;
    }
    else {
        return (<img src={`https://cdn.discordapp.com/emojis/${emotes[id - emojis.length]}?size=32`} style={{ bottom: 0, position: "absolute", marginLeft: "0.2em" }}></img>);
    }
}
const Rule = styled.hr `
  border-color: #666;
  width: 90%;
  margin: 2em auto;
`;
export default function Introduction() {
    return (<div style={{
            margin: "1em",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        }}>
      <h2 style={{ height: "48px" }}>
        <span className="fadein" style={{ display: "inline-block", position: "relative" }}>
          Hi, I'm Curtis. {emoji()}
        </span>
      </h2>
      <div>
        <p>
          I'm a Software Engineer-In-Training, specializing in fullstack webapp
          development. I like turning ideas into systems.
        </p>
        <p>
          I believe in building software without compromises in security,
          privacy and usability. My dream is to see an internet built around the
          principles of decentralized software and peer-to-peer networking,
          where everyone is in control of their data.
        </p>
      </div>
      <Rule />
      <div>
        <p>
          I currently work for&nbsp;
          <Link color="#ff5c35" href="https://www.hubspot.com">
            HubSpot
          </Link>{" "}
          in Cambridge, Massachusetts as a Frontend Software Engineering Intern,
          where I get to work on some of the product's security features and
          learn about development in a fast paced SaaS environment.
        </p>
        <p>
          I'm also a senior at{" "}
          <Link color="#ef4a63" href="https://carleton.ca/">
            Carleton University
          </Link>{" "}
          in Ottawa, Ontario, studying for my B.Eng to become a certified
          Software Engineer.
        </p>
        <p>
          In the past, I've had the pleasure of working with{" "}
          <Link color="#70c1ff" href="https://www.bell.ca/">
            Bell Canada
          </Link>
          's Corporate Security division and{" "}
          <Link color="#fecd08" href="https://gochartd.com/">
            Chartd Inc
          </Link>
          , also as a developer.
        </p>
        <p>
          I'm most experienced with building applications with{" "}
          <Color color="#6798f4">TypeScript</Color>,{" "}
          <Color color="#abe338">Node.js</Color> and{" "}
          <Color color="#61dafb">React</Color>, although I'm easily adaptable
          and have worked with a multitude of other technologies like C, Python,
          Java, Rust, Lisp and more.
        </p>
      </div>
      <Rule />
      <div>
        <p>
          Outside of my professional life, I enjoy playing video games, learning
          new things and going camping. I speak fluent English and French, and
          I'm currently learning Japanese, too. 楽しいです！
        </p>
      </div>
    </div>);
}
function Color(props) {
    return <span style={{ color: props.color }}>{props.children}</span>;
}
function Link(props) {
    return (<a {...props} target="_blank" style={Object.assign(Object.assign({}, props.style), { color: props.color })}></a>);
}
