import styled from "styled-components";
import Header from "./components/Header";
import Main from "./components/Main";
const Container = styled.div `
  width: 100%;
  max-width: 1200px;
  margin: auto;
`;
const App = () => {
    return (<Container>
      <Header />
      <Main />
    </Container>);
};
export default App;
